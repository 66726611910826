import { ReactComponent as HomeIcon } from '../../../../assets/icons/home.svg';
import { ReactComponent as LocateIcon } from '../../../../assets/icons/locate.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/create.svg';
export const Plot = ({ id, number, selectDevelopment, makeSelectLocation }) => {
  const handleSelectDevelopment = () => {
    if (!selectDevelopment) return;
    selectDevelopment(id);
  };

  const handleMakeSelectLocation = () => {
    selectDevelopment(id);
    makeSelectLocation();
  };

  return (
    <div className='plot' id={id} onClick={handleSelectDevelopment}>
      <HomeIcon fill='#fff' />
      <h4>Plot {number || id}</h4>
      <button onClick={handleSelectDevelopment}>
        <EditIcon />
      </button>
      <button onClick={handleMakeSelectLocation}>
        <LocateIcon fill={'#fff'} />
      </button>
    </div>
  );
};