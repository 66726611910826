import schema from '../schemas/_streetView';

const modalConfigReducer = (state = schema, action) => {
  switch (action.type) {
    case 'SET_MODAL_CONFIGRATIONS':
      return {
        ...state,
        modalConfig: action.data,
      };
    default:
      return {
        ...state,
      };
  }
};

export { modalConfigReducer };
