import { Streetview } from "@mui/icons-material";
import { ReactComponent as LocateIcon } from '../../../../assets/icons/locate.svg';

export const Street = ({ id, selectDevelopment, makeSelectLocation }) => {
  const handleSelectDevelopment = () => {
    if (!selectDevelopment) return;
    selectDevelopment(id);
  };

  const handleMakeSelectLocation = () => {
    selectDevelopment(id);
    makeSelectLocation();
  };

  return (
    <div className='plot street' id={id}>
      <Streetview fill='#fff' />
      <h4>Street Pin {id}</h4>
      <button onClick={handleMakeSelectLocation}>
        <LocateIcon fill={'#fff'} />
      </button>
    </div>
  );
};