import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';
import * as Sentry from "@sentry/react";
import './styles/_globals.scss';
import './styles/_buttons.scss';

Sentry.init({
  dsn: "https://a71080a5fabfcdfe99b2f32d1800c742@o4505013671559168.ingest.sentry.io/4505736739094528",
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ["localhost", "https://cms.signtouch.co.uk/api/"],
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: process.env.NODE_ENV !== 'development',
});


const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);